import React from 'react';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { Button } from '../../components';
import { useIntl } from 'react-intl';

import css from './SearchPage.module.css';

const ViewSelector = props => {
  const { className, selectedView, onSelect, onMobileMapClick } = props;

  const config = useConfiguration();
  const intl = useIntl();

  const { viewSelector } = config.search;

  const classes = classNames(css.viewSelector, className);

  return (
    <div className={classes}>
      {viewSelector.options.map(option => (
        <Button
          className={classNames(css.viewButton, {
            [css.viewSelected]: selectedView === option.key,
          })}
          key={option.key}
          type="button"
          onClick={() => {
            if (option.key === 'map') onMobileMapClick();

            onSelect({ [viewSelector.queryParamName]: option.key });
          }}
        >
          {intl.formatMessage({ id: option.label })}
        </Button>
      ))}
    </div>
  );
};

export default ViewSelector;
